.options-group {
  position: relative;
  flex-direction: column;
  display: none;
  box-sizing: content-box;
  width: calc(100% - 16px);
  flex-grow: 0;
  flex-shrink: 0;
  padding: 10px 0 0 10px;
  font-size: $size-16;

  &--visible {
    display: flex;
  }
}

.options-group__inner {
  width: 100%;
  height: 100%;
}

.options-group__content {
  position: relative;
  left: 0;
  flex-basis: 100%;
  height: auto !important;
  display: block !important;
  overflow: initial !important;

  @include breakpoint($m) {
    position: relative;
    left: 0;

    height: auto !important;
    display: block !important;
    overflow: initial !important;
  }
}

.options-group--open > .options-group__content {
  position: relative;
  left: 0;
}

.options-group__content--transitioning {
  transition: height 250ms;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}

.options-group__label {
  font-size: 16px;
  line-height: 1.3;
  color: $color-white;
  padding: 0 0 8px 0;
  display: block;
  width: 100%;
  text-align: left;
  position: relative;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  background-color: transparent;
  -webkit-tap-highlight-color: transparent;
  cursor: pointer;
  padding-right: $size-30;
  font-style: italic;

  /* @include breakpoint(0, $s) { */
  /*     padding-left: $size-16; */
  /* } */

  @include breakpoint($s) {
    &:focus {
      outline: none;
    }
  }
}

.options-group__option-children {
  display: none;
  padding-left: 30px;

  &--transitioning {
    transition: height 150ms;
  }

  .options-group__item--expanded & {
    display: block;
  }
}

.options-group__selected-legend {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: $color-gray-bright;
  padding: $size-16 $size-60 $size-16 $size-30;
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  left: -9999px;

  @include breakpoint(0, $s) {
    padding-left: $size-16;
  }
}

.options-group__badge {
  position: absolute;
  top: 50%;
  right: 30px;
  width: 30px;
  height: 30px;
  margin-top: -15px;
  border-radius: 100%;
  background: $color-blue;
  display: block;
  color: $color-white;
  line-height: 32px;
  text-align: center;
  z-index: $z-promoted;
  left: -9999px;
}

.options-group__clear-button {
  width: 24px;
  height: 24px;
  position: absolute;
  top: 50%;
  margin-top: -18px;
  z-index: 200;
  background-image: url('./img/close.svg');
  background-repeat: no-repeat;
  background-position: center center;
  right: 0;
  opacity: 0.8;
  display: none;

  .options-group--toggleable & {
    display: block;
  }

  &:hover {
    opacity: 1;
  }
}

.options-group__item {
  position: relative;
}

.options-group__option {
  position: absolute;
  left: -9999px;
}

.options-group__option-label {
  position: relative;
  display: block;
  color: $color-white;
  padding: 0 4px 4px $size-40;
  font-size: $size-16;
  line-height: 1.5;
  cursor: pointer;
  // NOTE: Transition disabled. It is currently not used in this application,
  // and it is not clear where this variable is defined in roxtec-web-frontend.
  //transition: background-color .2s $easing;
  margin-bottom: 2px;

  &::selection {
    background: transparent;
  }

  &::-moz-selection {
    background: transparent;
  }

  &:before {
    content: ' ';
    display: block;
    position: absolute;
    left: 10px;
    top: 5px;
    width: 20px;
    height: 20px;
    border: 2px solid $color-white;
    background: $color-white;
  }
}

.options-group__option--empty + .options-group__option-label {
  display: none;
}

.options-group__option:checked + .options-group__option-label,
.options-group__item--has-selected-children > .options-group__option-label {
  color: $color-blue;
  font-weight: 700;

  &:before {
    border-color: $color-blue;
    background-color: $color-blue;
    background-image: url('./img/check.svg');
    background-repeat: no-repeat;
    background-position: center center;
  }
}

.section--ocean-blue
  .options-group__option:checked
  + .options-group__option-label {
  color: black;
}

.options-group__item--has-selected-children > .options-group__option-label {
  &:before {
    color: $color-white;
  }
}

.options-group__icon {
  fill: $color-gray-dark;
  width: 25px;
  height: 25px;
  position: absolute;
  top: 11px;
  left: $size-16;
  display: inline-block;

  @include breakpoint(0, $s) {
    left: $size-16;
  }
}

.options-group__option:checked
  + .options-group__option-label
  .options-group__icon {
  fill: $color-blue;
}

.options-section {
  position: relative;
  flex-direction: column;
  display: none;
  box-sizing: content-box;
  width: calc(100% - 16px);
  flex-grow: 0;
  flex-shrink: 0;
  padding: 0 8px;
  padding-bottom: 18px;
  font-size: $size-16;

  @include breakpoint($xs) {
    width: calc(50% - 16px);
  }

  @include breakpoint($s) {
    padding: 0 $size-16;
    padding-bottom: $size-16;
    width: calc(33.33% - 32px);
  }

  @include breakpoint($m) {
    width: calc(25% - 32px);
  }

  @include breakpoint($xl) {
    width: calc(20% - 32px);
  }

  &--visible {
    display: flex;
  }
}

.options-section__inner {
  width: 100%;
  height: 100%;
}
.options-section__label {
  margin: 0;
  font-size: 20px;
  line-height: 1.3;
  font-weight: bold;
  color: $color-white;
  padding: 0 0 8px 0;
  border-bottom: 4px solid $color-gray-medium;
  display: block;
  width: 100%;
  text-align: left;
  position: relative;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  background-color: transparent;
  -webkit-tap-highlight-color: transparent;
  cursor: pointer;
  padding-right: $size-30;

  /* @include breakpoint(0, $s) { */
  /*     padding-left: $size-16; */
  /* } */

  @include breakpoint($s) {
    &:focus {
      outline: none;
    }
  }
}
