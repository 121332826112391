.fact {
  font-size: $size-16;
}

.fact__item {
  display: inline-block;
  vertical-align: top;
  width: 100%;
  margin-bottom: $size-12;

  @include breakpoint(0, $xs) {
    &:last-child {
      margin-bottom: 0;
    }
  }

  @include breakpoint($s) {
    margin-bottom: $size-18;
  }
}

.fact .fact__item-title {
  // double specificity to override styles inherited from `.rich-text`
  font-weight: 700;
  font-size: $size-18;
  line-height: 1.3;
  color: $color-green;
  margin: 0;

  @include breakpoint($s) {
    font-size: $size-20;
    line-height: 1.3;
  }
}

.fact .fact__item-content {
  // double specificity to override styles inherited from `.rich-text`
  margin: 0;
  font-size: $size-16;
  line-height: 1.5;

  @include breakpoint($s) {
    font-size: $size-18;
    line-height: 1.6;
  }
}
