.modal {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(50, 62, 70, 0.2);
  z-index: 100;
  //transition-property: left, background-color;
  //transition-duration: 0ms, 0.2s;
  //transition-delay: 0.2s, 0ms;
  //display: none;
  overflow: scroll;
  -webkit-overflow-scrolling: touch;
  text-align: left;
  font-size: 16px;

  .modal__box {
    position: relative;
    width: 100%;
    min-height: calc(100vh + 80px);
    background: #fff;
    //transform: translate3d(0, 600px, 0);
    //opacity: 0;
    transition: all 0.2s;
    //transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transform: translateZ(0);
  }

  .modal__header {
    padding: 24px 16px;
    padding-right: 60px;
    background: #0091d3;
    color: #fff;
    position: relative;
  }

  .modal__close-button {
    width: 30px;
    height: 30px;
    background: transparent;
    border: 0;
    position: absolute;
    right: 16px;
    top: 50%;
    margin-top: -15px;
    padding: 0;
    color: inherit;
    svg {
      fill: #fff;
    }
  }
  .modal__title {
    font-weight: 900;
    font-size: 24px;
    line-height: 1.2;
    margin: 0;
    position: relative;
    padding-right: 32px;
  }

  .modal__content {
    padding: 32px 16px;
    width: 100%;
    vertical-align: top;
    text-align: left;
    min-height: calc(100vh - 124px);
  }

  @media screen and (min-width: $s) {
    .modal__inner {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      text-align: center;
      &:before {
        content: '';
        display: inline-block;
        height: 100%;
        vertical-align: middle;
        margin-right: -0.25em;
      }
    }
    .modal__box {
      width: 98%;
      max-width: 1140px;
      min-height: 0;
      display: inline-block;
      vertical-align: middle;
      text-align: left;
    }
    .modal__header {
      padding: 30px;
    }
    .modal__title {
      text-align: center;
      font-size: 32px;
    }
    .modal__close-button {
      margin-top: 0;
      right: 24px;
      top: 24px;
    }
    .modal__content {
      min-height: 0;
      padding: 4% 6.5%;
    }
  }
}
