.searchfield {
  position: relative;
  font-size: $size-16;
  width: 100%;

  &:before {
    content: ' ';
    display: block;
    position: absolute;
    top: 12px;
    bottom: 12px;
    left: 0;
    width: 50px;
    border-right: 1px solid $color-gray-light;
    background-image: url('./img/magnifier.svg');
    background-position: center center;
    background-repeat: no-repeat;

    @include breakpoint(0, $xs) {
      width: 40px;
      background-size: $size-18 $size-18;
    }
  }

  &--large:before {
    @include breakpoint($s) {
      width: $size-64;
      background-size: $size-32 $size-32;
    }
  }

  &--white,
  &--transparent {
    border-right-color: #979797;
  }
}

.searchfield__input {
  width: 100%;
  border: 2px solid $color-gray-light;
  font-size: $size-16;
  font-weight: bold;
  color: $color-dark;
  padding: $size-12 $size-12 $size-12 $size-50;
  appearance: none;
  border-radius: 0;

  @include breakpoint($xs) {
    padding: $size-18 $size-60;
    font-size: $size-18;
  }

  .searchfield--inline & {
    padding-right: $size-12;

    @include breakpoint($xs) {
      padding-right: $size-18;
    }
  }

  .searchfield--small & {
    /* Uneven numbers to align with buttons */
    padding-top: 12px;
    padding-bottom: 11px;
  }

  .searchfield--large & {
    @include breakpoint($s) {
      padding-top: $size-32;
      padding-bottom: $size-32;
      padding-left: $size-80;
      font-size: $size-20;
    }
  }

  @include placeholder {
    color: $color-gray-medium;
  }

  &::-webkit-search-decoration,
  &::-webkit-search-cancel-button,
  &::-webkit-search-results-button,
  &::-webkit-search-results-decoration {
    display: none;
  }

  .searchfield--white & {
    border-color: $color-white;
    background: $color-white;
  }

  .searchfield--transparent & {
    color: $color-gray-light;
    background: transparent;
  }
}

.searchfield__submit,
.searchfield__button {
  position: absolute;
  top: 50%;
  right: 12px;
  transform: translateY(-50%);
}

.searchfield__button {
  height: 40px;
  outline: none;
  border-width: 0;
  appearance: none;
  width: 50px;
  background-color: transparent;
  background-image: url('./img/arrow.svg');
  background-position: center center;
  background-repeat: no-repeat;
  display: none;

  @include breakpoint($xs) {
    display: block;
  }

  .searchfield--large & {
    @include breakpoint($s) {
      background-size: $size-32 $size-32;
    }
  }
}
