.rt-top {
    font-size: $size-14;
    position: relative;
}

.rt-top__status {
    display: inline-block;
    vertical-align: top;
    margin-right: 2px;
}

.rt-top__title {
    font-size: $size-18;
    line-height: $size-28;
    padding-right: $size-40;
    margin: 0;

    @include breakpoint($s) {
        font-size: $size-28;
        line-height: $size-32;
        padding-right: $size-64;
    }
}

.rt-top__content {
    @include breakpoint($m) {
        display: flex;
    }

    .rt-top__title + & {
        margin-top: $size-16;
    }
}

.rt-top__actions {
    margin-top: $size-16;

    @include breakpoint($m) {
        flex-basis: 300px;
        flex-grow: 0;
        flex-shrink: 0;
        display: flex;
        flex-direction: column;
        margin-top: 0;

        > * {
            margin-top: auto;
            width: 100%;
        }
    }
}

.rt-top__meta {
    flex-basis: 100%;
    flex-shrink: 1;
    margin-right: -$size-16;

    @include breakpoint($s) {
        margin-right: -$size-40;
    }

    &:after {
        content: "";
        display: table;
        clear: both;
    }
}

.rt-top__table {
    display: table;
    width: 100%;

    @include breakpoint($xs) {
        float: left;
        width: 50%;
        padding-right: $size-16;
    }

    @include breakpoint($s) {
        width: auto;
        padding-right: $size-40;
    }

    @include breakpoint($m) {
        padding-right: $size-64;
    }
}

.rt-top__table-row {
    display: table-row;
}

.rt-top__table-key,
.rt-top__table-value {
    display: table-cell;
    @include breakpoint(0, $xs) {
        width: 50%;
    }
}

.rt-top__table-key {
    font-weight: bold;
    padding-right: $size-16;
}

.rt-top__edit {
    position: absolute;
    top: 0;
    right: 0;
}

.user-card {
  overflow: auto;
  .user-card__details {
    padding: $size-12 0 $size-20;
    .user-card__details__label {
      padding-right: $size-16;
    }
  }
}
