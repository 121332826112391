/* These mixins are needed until we deprecated .rt-form__select and .rt-form__text */
@mixin formItemText {
  border: 2px solid $color-gray-light;
  padding: 13px $size-16; /* Uneven to align with buttons */
  color: $color-dark;
  font-size: $size-16;
  background: $color-white;
  width: 100%;
  display: block;
  border-radius: 0;
  outline: none;

  &:focus {
    border-color: $color-blue;
  }
}

@mixin formItemSelect {
  @include formItemText();

  background: $color-white;
  border: 2px solid $color-gray-light;
  color: $color-dark;
  font-size: $size-16;
  position: relative;
  appearance: none;
  font-size: $size-16;
  background-image: url('./img/chevron.svg');
  background-position: calc(100% - 20px) center;
  background-repeat: no-repeat;
  padding-right: $size-40;

  &:focus::-ms-value {
    color: $color-black;
    background: transparent;
  }
}

.form-item {
  display: inline-block;
  width: 100%;
  margin-bottom: $size-16;
  vertical-align: top;
  position: relative;
  font-size: $size-16;

  &--filled {
    display: block;
    padding: $size-12;
    background: $color-gray-light;
  }

  &--block {
    display: block;
  }

  &--autocomplete-open {
    z-index: $z-modal;
  }

  &:last-child {
    margin-bottom: 0;
  }

  &--with-info {
    padding-right: $size-50;
  }

  &--checkbox,
  &--radio {
    width: auto;
  }

  &--half {
    width: 50%;
    padding-right: $size-8;

    &:nth-child(even) {
      padding-right: 0;
      padding-left: $size-8;
    }
  }

  &--inline {
    @include breakpoint($xs) {
      padding-left: 33.33%;
    }
  }
}

.form-item__label {
  font-weight: bold;
  font-size: $size-14;
  display: block;
  margin-bottom: $size-4;

  @include breakpoint($xs) {
    .form-item--inline & {
      position: absolute;
      top: $size-16;
      left: 0;
      width: 33.33%;
      padding-right: $size-16;
    }
  }

  .form-item--radio &,
  .form-item--checkbox & {
    display: inline-block;
    min-width: 200px;
    padding-left: $size-40;
    font-size: $size-16;
    margin-bottom: 0;
    color: $color-blue;
    position: relative;
    min-height: $size-24; // Must be set to align checkbox/radio correctly

    &::selection {
      background: transparent;
    }

    &:before,
    &:after {
      content: ' ';
      position: absolute;
      display: block;
    }

    &:before {
      top: 0;
      left: 0;
      width: $size-24;
      height: $size-24;
      background: $color-white;
      border: 2px solid $color-gray-medium;
    }

    &:after {
      width: $size-14;
      height: $size-14;
      top: 12px;
      left: 12px;
      margin: -7px 0 0 -7px;
      color: $color-blue;
      z-index: 1;
      opacity: 0;
      transition: opacity 0.2s;
      background: url('./img/check.svg') center center
        no-repeat;
    }
  }

  .form-item--radio & {
    &:before {
      border-radius: 100%;
    }

    &:after {
      background: $color-blue;
      border-radius: 100%;
    }
  }

  .form-item--radio .form-item__element:checked + &,
  .form-item--checkbox .form-item__element:checked + & {
    &:after {
      opacity: 1;
    }
  }

  @include breakpoint($s) {
    font-size: $size-14;
  }

  .form-item__element:disabled + & {
    opacity: 0.6;
  }
}

.form-item__element {
  width: 100%;
  display: block;
  border-radius: 0;
  appearance: none;
  outline: none;

  &[type='number']::-webkit-inner-spin-button,
  &[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  .form-item--radio &,
  .form-item--checkbox & {
    position: absolute;
    left: -9999px;
  }

  .form-item--text &,
  .form-item--email &,
  .form-item--tel &,
  .form-item--number &,
  .form-item--textarea &,
  .form-item--password & {
    /* We do this so the old rt-form__input--text can use these properties in a mixin. */
    @include formItemText();

    &--error {
      &,
      &:focus {
        border-color: $color-red;
      }
    }
  }

  .form-item--textarea & {
    resize: none;
  }

  // For select element. Removes IE arrow.
  &::-ms-expand {
    display: none;
  }
}

.form-item__error {
  display: block;
  color: $color-red;
  font-size: $size-14;
  font-weight: bold;
  margin-top: 2px;
}

.form-item--select .form-item__element {
  @include formItemSelect();
}

.form-item__info {
  position: absolute;
  display: block;
  border-radius: 100%;
  width: $size-32;
  height: $size-32;
  border: 2px solid $color-blue;
  color: $color-blue;
  text-align: center;
  outline: none;
  background: transparent;
  font-weight: bold;
  bottom: $size-14;
  right: $size-12;
  line-height: $size-28;
}

.form-item__spinner {
  position: absolute;
  bottom: 6px;
  right: 6px;
  opacity: 0;
  transition: opacity 10ms;
  transition-delay: 100ms;

  .form-item--loading & {
    opacity: 1;
    transition-delay: 0ms;
  }
}

.form-item__autocomplete-results {
  position: absolute;
  top: 100%;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.15);
  left: -9999px;
  right: -9000px;
  background: $color-white;
  opacity: 0;
  transition: left 0ms, right 0ms, opacity 120ms;
  transition-delay: 120ms, 0ms, 0ms;

  .form-item--autocomplete-open & {
    transition-delay: 0ms, 0ms, 0ms;
    left: 0px;
    right: 0px;
    opacity: 1;

    @include breakpoint($xs) {
      left: 33.33%;
    }
  }
}

.form-item__autocomplete-item {
  width: 100%;
  display: block;
  border: 0;
  border-bottom: 1px solid $color-gray-bright;
  text-decoration: none;
  color: $color-black;
  transition: background-color 150ms;
  appearance: none;
  outline: none;
  text-align: left;
  padding: $size-4 $size-16;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: $size-16;
  color: $color-dark;

  &:last-child {
    border-bottom-width: 0;
  }

  &:hover,
  &:focus {
    outline: none;
    background: $color-gray-bright;
  }
}

.form-item__children {
  margin-top: $size-16;
  padding-left: $size-16;
  display: none;

  .form-item__element:checked + .form-item__label + & {
    display: block;
  }
}
