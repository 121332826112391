.dropdown {
  font-size: $size-16;
  position: absolute;
  padding-top: $size-8;
  margin-top: 2px;
  width: 100%;
  top: 100%;
  opacity: 0;
  left: -9999px;
  transition: opacity 130ms, left 0ms, transform 0ms;
  transition-delay: 0ms, 140ms, 140ms;
  width: 290px;
  text-align: left;

  @include breakpoint($xs) {
    padding-top: $size-14;
    width: 350px;
  }

  &--open {
    opacity: 1;
    left: 50%;
    transform: translateX(-50%);

    &.dropdown--left {
      left: 0;
      transform: none;
    }

    &.dropdown--right {
      right: 0;
      left: auto;
      transform: none;
    }
  }
}

.dropdown__inner {
  box-shadow: 0 7px 20px rgba($color-black, 0.15);
  background: $color-white;
  color: $color-black;
  font-weight: normal;
  padding: $size-20;
  position: relative;

  &:after {
    content: ' ';
    bottom: 100%;
    left: 50%;
    border: solid transparent;
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-color: transparent;
    border-bottom-color: $color-white;
    border-width: $size-8;
    margin-left: -$size-8;

    @include breakpoint($xs) {
      border-width: $size-14;
      margin-left: -$size-14;
    }

    .dropdown--left & {
      left: $size-8;
      margin-left: 0;

      @include breakpoint($xs) {
        left: $size-16;
      }
    }

    .dropdown--right & {
      right: $size-8;
      margin-left: 0;
      left: auto;

      @include breakpoint($xs) {
        right: $size-16;
      }
    }
  }
}
