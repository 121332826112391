@mixin breakpoint($minBreakpoint, $maxBreakpoint: false) {
    @if $maxBreakpoint {
        @media screen and (min-width: $minBreakpoint) and (max-width: $maxBreakpoint - 1px) {
            @content;
        }
    } @else {
        @media screen and (min-width: $minBreakpoint) {
            @content;
        }
    }
}

@mixin fontSmoothing() {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

@mixin clearfix {
  &:after {
    content: "";
    display: table;
    clear: both;
  }
}

@mixin placeholder {
    &::-webkit-input-placeholder {
        @content
    }
    &:-moz-placeholder {
        @content
    }
    &::-moz-placeholder {
        @content
    }
    &:-ms-input-placeholder {
        @content
    }
}