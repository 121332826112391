$color-white: #fff;
$color-black: #000;
$color-dark: #323e46;
$color-ocean-blue: #00a7b5;
$color-blue: #0091d3;
$color-green: #34b68f;
$color-yellow: #fce400;
$color-warning: #f7f4da;

$color-gray-dark: #878786;
$color-gray-medium: #c6c6c5;
$color-gray-light: #ececec;
$color-gray-bright: #f5f5f5;

$color-red: #e63f16;
$color-purple: #5d1f84;
