/* .rt-top__title Taken from https://rtr.factor10labs.com/app/customers */
.rt-top__title {
  font-size: 18px;
  line-height: 28px;
  padding-right: 40px;
  margin: 0;
}
@media screen and (min-width: $s) {
  .rt-top__title {
    font-size: 28px;
    line-height: 32px;
    padding-right: 64px;
  }
}
@media screen and (min-width: $m) {
  .rt-top__content {
    display: -ms-flexbox;
    display: flex;
  }
}
.rt-top__actions,
.rt-top__title + .rt-top__content {
  margin-top: 16px;
}
