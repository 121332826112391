body {
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  text-size-adjust: 100%;
  margin: 0;
  padding: 0;
  color: #323e46;
  -webkit-font-smoothing: antialiased;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
}

select::-ms-expand {
  display: none;
}

select::focus::-ms-value {
  background-color: transparent;
}

*,
:after,
:before {
  box-sizing: border-box;
}

figure {
  margin: 0;
}

img {
  max-width: 100%;
  height: auto;
  vertical-align: bottom;
}

a {
  color: #0091d3;
}
