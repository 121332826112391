.layout-rt {
  width: 100vw;
  background: $color-gray-bright;

  &--white {
    background: $color-white;
  }

  @include breakpoint($s) {
    height: 100vh;
    position: relative;
    overflow: hidden;
  }
}

.layout-rt__main {
  width: 100%;
  flex-basis: 100%;
  padding-bottom: $size-80;

  @include breakpoint($s) {
    //padding-left: $size-80;
    height: 100vh;
    display: flex;
    flex-direction: column;
  }
}

.layout-rt__bar {
  min-height: $size-40;
  background: $color-white;
  border-bottom: 1px solid $color-gray-light;
  padding: $size-8 $size-16;
  flex-basis: auto;
  flex-shrink: 0;
  justify-content: space-between;

  @include breakpoint($s) {
    padding: $size-8 $size-30;
    display: flex;
  }

  &--bold {
    padding-top: $size-12;
    padding-bottom: $size-12;

    @include breakpoint($s) {
      padding-top: $size-16;
      padding-bottom: $size-16;
    }
  }

  &--sticky {
    position: sticky;
    top: 0;
    z-index: $z-promoted;
  }

  &--naked {
    background: transparent;
    border-bottom: 0;
  }
}

.layout-rt__bar-column {
  flex-basis: 100%;
  flex-grow: 1;
  flex-shrink: 1;
  margin-right: -$size-16;
  margin-bottom: $size-16;

  &:last-child {
    margin-bottom: 0;
  }

  @include breakpoint($xs) {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  @include breakpoint($s) {
    margin-bottom: 0;
  }

  > * {
    flex-grow: 1;
  }
}

.layout-rt__bar-item {
  flex-basis: 100%;
  flex-grow: 1;
  flex-shrink: 1;
  padding-right: $size-16;
  margin-bottom: $size-16;

  &:last-child {
    margin-bottom: 0;
  }

  @include breakpoint($xs) {
    margin-bottom: 0;
  }

  &--right {
    text-align: right;
  }
}

.layout-rt__footer {
  height: $size-80;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: $size-8 $size-16;
  border-top: 1px solid $color-gray-light;
  background: $color-white;
  z-index: $z-modal - 2;
  display: flex;
  flex-direction: row;
  justify-content: center;

  @include breakpoint($s) {
    position: fixed;
  }
}

.layout-rt__logo {
  width: 150px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.layout-rt__version {
  display: flex;
  font-size: 11px;
  justify-content: center;
  align-items: center;
}

.layout-rt__content {
  padding: $size-16;
  flex-basis: 100%;
  flex-shrink: 1;

  @include breakpoint($s) {
    overflow-y: auto;
    padding: $size-16 $size-30;
  }

  @supports (position: sticky) {
    overflow-y: initial;
  }

  .layout-rt__bar--naked + & {
    padding-top: 0;
  }
}

.layout-rt__body {
  position: relative;

  @include breakpoint($s) {
    display: flex;
    flex-direction: column;
    flex-basis: 100%;
  }

  @supports (position: sticky) {
    @include breakpoint($s) {
      overflow-y: auto;
      -webkit-overflow-scrolling: touch;
    }
  }
}

.layout-rt__footer-actions {
  margin-left: auto;
  display: flex;
  flex-direction: row;
  flex-basis: 100%;
}

.layout-rt__footer-action {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 12px;
}

.layout-rt__messages {
  position: absolute;
  top: $size-30;
  left: $size-16;
  right: $size-16;
  z-index: $z-modal;

  @include breakpoint($s) {
    left: $size-30 + $size-80;
    right: $size-30;
  }
}

/* .button Creuna assets 5.2.0 */

.button {
  display: inline-block;
  font-size: 16px;
  line-height: 1.5;
  padding: 12px 16px;
  border: 2px solid #0091d3;
  color: #0091d3;
  text-decoration: none;
  position: relative;
  background: transparent;
  font-weight: 700;
  text-align: center;
  transition: color 0.3s cubic-bezier(0.34, 1, 0.84, 1);
  min-width: 200px;
  cursor: pointer;
  border-radius: 0;
  min-height: 52px;
}
@media screen and (min-width: 767px) {
  .button {
    min-height: 55px;
    padding: 12px 24px;
    font-size: 18px;
    line-height: 1.5;
  }
}
.button:before {
  content: ' ';
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 100%;
  background: #0091d3;
  transition: width 0.3s cubic-bezier(0.34, 1, 0.84, 1);
}
@media screen and (min-width: 767px) {
  .button:not([disabled]):hover {
    color: #fff;
  }
  .button:not([disabled]):hover:before {
    width: 100%;
  }
}
.button--selected {
  color: #fff;
}
@media screen and (min-width: 0) and (max-width: 766px) {
  .button--selected {
    background-color: #0091d3;
  }
}
.button--selected:before {
  width: 100%;
}
.button--white {
  color: #fff;
  border-color: #fff;
}
.button--white:not([disabled]):hover {
  color: #0091d3;
}
.button--white:before {
  background: #fff;
}
.button--white.button--selected,
.button--white.button--selected:hover {
  background-color: #fff;
  color: #323e46;
}
.button--add-to-cart,
.button--secondary {
  color: #34b68f;
  border-color: #34b68f;
}
.button--add-to-cart:before,
.button--secondary:before {
  background: #34b68f;
}
.button--secondary.button--solid,
.button--solid.button--add-to-cart {
  color: #fff;
}
.button--secondary.button--solid:not([disabled]):hover,
.button--solid.button--add-to-cart:not([disabled]):hover {
  border-color: #298e70;
}
.button--secondary.button--solid:not([disabled]):hover:before,
.button--solid.button--add-to-cart:not([disabled]):hover:before {
  background-color: #298e70;
}
.button--gray-dark {
  color: #fff;
  border-color: #878786;
}
.button--gray-dark:before {
  background: #878786;
}
.button--gray-dark.button--solid {
  color: #fff;
}
.button--gray-dark.button--solid:not([disabled]):hover {
  border-color: #6d6d6d;
}
.button--gray-dark.button--solid:not([disabled]):hover:before {
  background-color: #6d6d6d;
}
.button--warning {
  color: #e63f16;
  border-color: #e63f16;
}
.button--warning:before {
  background: #e63f16;
}
.button--warning.button--solid {
  color: #fff;
}
.button--warning.button--solid:not([disabled]):hover {
  border-color: #b73212;
}
.button--warning.button--solid:not([disabled]):hover:before {
  background-color: #b73212;
}
.button--cancel {
  color: #c6c6c5;
  border-color: #c6c6c5;
}
.button--cancel:before {
  background: #c6c6c5;
}
.button--icon {
  display: block;
  max-width: 500px;
  text-align: left;
}
.button--inline {
  display: inline-block;
  vertical-align: top;
}
.button--small {
  min-width: 0;
}
.button__text {
  position: relative;
  z-index: 1;
  display: inline-block;
  pointer-events: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.button--icon .button__text {
  padding-right: 32px;
}
.button__icon {
  width: 30px;
  height: 30px;
  position: absolute;
  top: 50%;
  margin-top: -15px;
  right: 1px;
  z-index: 1;
  fill: currentColor;
  pointer-events: none;
  transition: fill 0.1s linear;
}
@media screen and (min-width: 767px) {
  .button__icon {
    right: 4px;
  }
}
.button__text + .button__icon {
  right: 16px;
}
@media screen and (min-width: 767px) {
  .button__icon {
    width: 40px;
    height: 40px;
    margin-top: -20px;
  }
}
.button__loader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  width: 35px;
  height: 35px;
  fill: #000;
  z-index: 1;
  display: none;
}
@media screen and (min-width: 767px) {
  .button:not([disabled]):hover .button__icon {
    transition-delay: 0.1s;
    transition-duration: 0.2s;
  }
}
.ios-device .button:before {
  display: none;
}
.ios-device .button--solid:before {
  display: block;
}
.button--solid {
  color: #fff;
  transition-property: border-color;
  transition-duration: 0.12s;
}
.button--solid:before {
  width: 100%;
  transition-property: background-color;
  transition-duration: 0.12s;
}
.button--solid:not([disabled]):hover {
  border-color: #006ea0;
}
.button--solid:not([disabled]):hover:before {
  background-color: #006ea0;
}
.button[disabled] {
  opacity: 0.5;
}
.button--add-to-cart.button--solid {
  color: #fff;
}
.button--add-to-cart.button--solid:hover {
  border-color: #298e70;
}
.button--add-to-cart.button--solid:hover:before {
  background-color: #298e70;
}
