.filterblock {
  font-size: $size-16;
}

.filterblock__title {
  color: $color-white;
  font-size: $size-20;
  margin: 0 0 $size-12;
}

.filterblock__action + .filterblock__action {
  margin-top: $size-24;
}

.filterblock__search + .filterblock__grid {
  margin-top: $size-16;
}

.filterblock__grid {
  display: flex;
  flex-wrap: wrap;
  margin-left: -8px;
  margin-right: -8px;
  margin-bottom: -8px;

  @include breakpoint($s) {
    margin-left: -$size-16;
    margin-right: -$size-16;
    margin-bottom: -$size-16;
  }
}
