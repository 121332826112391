.customer-bar {
    background: $color-white;
    margin-bottom: 2px;
    padding: $size-16 $size-64 $size-16 $size-16;
    min-height: $size-80;
    font-size: $size-14;
    line-height: $size-20;
    position: relative;

    &:last-child {
        margin-bottom: $size-16;
    }

    @include breakpoint($s) {
        font-size: $size-16;
        line-height: $size-24;
    }

    @include breakpoint(0, $s) {
        .layout-rt__content & {
            margin-left: -$size-16;
            margin-right: -$size-16;
        }
    }
}

.customer-bar__title {
    font-size: $size-14;
    margin-top: 0;
    margin-bottom: $size-4;

    @include breakpoint($s) {
        font-size: $size-18;
    }

    a {
        color: $color-blue;
        text-decoration: none;

        &:hover {
            text-decoration: underline;
        }
    }

    span {
        margin-left: 8px;
    }
}

.customer-bar__edit {
    position: absolute;
    top: $size-16;
    right: $size-16;

    @include breakpoint($xs) {
        top: 50%;
        margin-top: -$size-20;
        width: $size-40;
        height: $size-40;
    }
}

.customer-bar__check {
    position: absolute;
    left: $size-16;
    top: 50%;
    transform: translateY(-50%);
}

.customer-bar__download {
    position: absolute;
    top: $size-16;
    right: $size-16;

    @include breakpoint($xs) {
        top: 50%;
        margin-top: -$size-20;
        width: $size-40;
        height: $size-40;
    }
}

.customer-bar__check {
    position: absolute;
    left: $size-16;
    top: 50%;
    transform: translateY(-50%);
}

.customer-bar__meta {
    font-size: $size-14;
    line-height: $size-18;
    @include clearfix();
}

.customer-bar__table {
    @include breakpoint($xs) {
        display: table;
    }
}

.customer-bar__table-row {
    display: inline-block;
    vertical-align: top;

    @include breakpoint($xs) {
        display: table-row;

        &:after {
            display: none;
        }
    }
}

.customer-bar__table-value {
    display: block;

    &:after {
        content: "/";
        margin-left: .4em;
        margin-right: .4em;
        color: $color-gray-dark;

        .customer-bar__table-row:last-child & {
            display: none;
        }

        @include breakpoint($xs) {
            display: none;
        }
    }

    @include breakpoint($xs) {
        display: table-cell;
    }
}

.customer-bar__table-key {
    font-weight: bold;
    padding-right: $size-16;
    display: none;

    @include breakpoint($xs) {
        display: table-cell;
    }
}
