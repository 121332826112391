.section--blue {
    background: $color-blue;
    color: $color-white;

    .section__title,
    a {
        color: inherit;
    }
}

.section--ocean-blue {
    background: $color-ocean-blue;
    color: $color-white;

    .section__title,
    a {
        color: inherit;
    }
}

.section--gray-medium {
    background: $color-gray-medium;

    .section__title {
        color: $color-white;
    }
}

.section--gray-light {
    background: $color-gray-light;
}

.section--gray-bright {
    background: $color-gray-bright;

    .section__title {
        color: $color-blue;
    }
}

.section--dark {
    background: $color-dark;
    color: $color-white;

    .section__title,
    a {
        color: $color-green;
    }

  &.section--toggleable {
    background: $color-gray-dark;

    &:hover, &[aria-expanded="true"] {
      background: $color-dark;
    }
  }
}

.section__title--dark {
    color: $color-dark !important;
}

.section__title--white {
    color: $color-white !important;
}
