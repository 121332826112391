.section {
  position: relative;
  transition: max-height 500ms, background 200ms;

  // this is needed to make the section "utfallande" when it has `.rich-text` as a parent
  .rich-text & {
    &:before {
      content: ' ';
      position: absolute;
      top: 0;
      bottom: 0;
      width: 10000px; // Very long so it fills entire width of browser
      margin-left: -5000px;
      background: inherit;
    }
  }

  &--border-top {
    border-top: 1px solid $color-gray-light;
  }
}

.section__inner {
  padding: $size-30 0;
  margin-left: $size-16;
  margin-right: $size-16;
  position: relative;
  z-index: $z-promoted;

  .body-wrapper--centered & {
    max-width: 1400px;
  }

  .section--full & {
    max-width: 100%;
  }

  .section--promoted & {
    z-index: $z-promoted + 1;
  }

  &--less-padding {
    padding: $size-18 0;
  }

  @include breakpoint($s) {
    padding: $size-60 0;
    margin-left: $size-32;
    margin-right: $size-32;

    &--less-padding {
      padding: $size-30 0;
    }
  }

  @include breakpoint($xl) {
    margin-left: 64px;
    margin-right: 64px;
  }
}

.section__title {
  font-size: $size-60;
  font-weight: 900;
  font-size: $size-32;
  line-height: 1.25;
  margin-top: 0;
  margin-bottom: $size-24;
  color: $color-blue;
  @include fontSmoothing();

  &--small {
    font-size: $size-24;
    line-height: 1.4;
  }

  &--smaller {
    font-size: $size-20;
    line-height: 1.4;
    margin-bottom: $size-18;
  }

  &--dark {
    color: $color-dark;
  }

  @include breakpoint($s) {
    font-size: $size-40;
    line-height: 1.2;

    &--small {
      font-size: $size-32;
    }

    &--smaller {
      font-size: $size-24;
      line-height: 1.4;
      margin-bottom: $size-24;
    }
  }

  @include breakpoint($l) {
    font-size: $size-60;
    line-height: 0.9;

    &--small {
      font-size: $size-40;
      line-height: 1.2;
    }

    &--smaller {
      font-size: $size-24;
      line-height: 1.4;
    }
  }

  transition: margin 30ms;

  .section--toggleable & {
    user-select: none;
    cursor: pointer;
    position: relative;

    ::-moz-selection {
      background: transparent;
    }

    ::selection {
      background: transparent;
    }

    &:before {
      content: '';
      transition: transform 150ms;
      transform: rotate(0deg);
      transform-origin: center;
      width: 30px;
      height: 30px;
      position: absolute;
      top: 50%;
      right: $size-12;
      margin-top: -15px;
      // NOTE: image path broken
      //background-image: url(img/chevron.svg);
      background-position: center center;
      background-repeat: no-repeat;
    }
  }

  .section--toggleable & {
    margin: (-$size-30) (-$size-16);
    padding: $size-30 $size-16;
    padding-right: $size-16 + $size-40;

    &:before {
      right: $size-12;
    }

    @include breakpoint($s) {
      margin: (-$size-60) (-$size-32);
      padding: $size-60 $size-32;
      padding-right: $size-32 + $size-40;

      &:before {
        right: $size-12 + $size-30;
      }
    }

    @include breakpoint($xl) {
      margin-left: -64px;
      margin-right: -64px;
      padding-left: 64px;
      padding-right: 64px + $size-40;

      &:before {
        right: $size-12 + 48px;
      }
    }
  }

  .section--toggleable .section__inner--less-padding & {
    margin: (-$size-18) (-$size-16);
    padding: $size-18 $size-16;
    padding-right: $size-16 + $size-26;

    &:before {
      right: $size-12;
    }

    @include breakpoint($s) {
      margin: (-$size-30) (-$size-32);
      padding: $size-30 $size-32;
      padding-right: $size-32 + $size-40;

      &:before {
        right: $size-12 + $size-16;
      }
    }

    @include breakpoint($xl) {
      margin-left: -64px;
      margin-right: -64px;
      padding-left: 64px;
      padding-right: 64px;
      padding-right: 64px + $size-40;

      &:before {
        right: $size-12 + 48px;
      }
    }
  }

  .section--toggleable[aria-expanded='true'] & {
    &:before {
      transform: rotate(180deg);
    }
  }

  &--sub {
    margin-bottom: 0;
  }
}

.section__description {
  font-size: $size-18;
  line-height: 1.5;
  margin-top: -12px;

  p {
    margin-top: 0;
    margin-bottom: $size-24;
  }

  a {
    text-decoration: none;
    font-weight: 700;
  }
}

.section__description--narrow {
  @include breakpoint($s) {
    padding: 0;
    font-size: $size-16;
  }

  @include breakpoint($m) {
    padding-right: 17%;
  }

  @include breakpoint($l) {
    font-size: $size-18;
    padding-right: 390px;
  }
}

.section__title + .section__content,
.section__description + .section__content,
.section__content + .section__title {
  margin-top: $size-18;

  @include breakpoint($s) {
    margin-top: $size-32;
  }
}

.section__title--smaller + .section__content {
  margin-top: 0;

  @include breakpoint($s) {
    margin-top: $size-8;
  }
}

.section__title--sub + .section__content {
  margin-top: 0;
}

.section__content {
  .section--toggleable[aria-expanded='false'] & {
    opacity: 0;
    position: absolute;
    left: -9999px;
    transition: left 0ms, transition 150ms;
    transition-delay: 150ms, 0ms;
  }

  .section--toggleable[aria-expanded='true'] & {
    transition-delay: 0ms, 150ms;
    position: relative;
    left: 0;
  }

  &--small {
    font-size: $size-16;
  }
}

.section__content--flex {
  display: flex;
  flex-wrap: wrap;

  &.section__content--4-col {
    margin-right: -$size-16;
    margin-bottom: -$size-16;

    > * {
      margin-right: $size-16;
      margin-bottom: $size-16;
      flex-basis: calc(100% - 16px);
      flex-grow: 0;
      flex-shrink: 0;
      box-sizing: content-box;

      @include breakpoint($xs) {
        flex-basis: calc(50% - 16px);
      }

      @include breakpoint($m) {
        flex-basis: calc(25% - 16px);
      }
    }
  }
}

.section__content--spacing {
  margin-bottom: $size-18;

  @include breakpoint($s) {
    margin-bottom: $size-24;
  }

  &:last-child {
    margin-bottom: 0;
  }
}

.section__action {
  margin-top: $size-30;
  margin-bottom: $size-50 + $size-12; // Add 12px because desc margin top is -12px

  &:last-child {
    margin-bottom: 0;
  }
}

@import './colors';

.section--gray-medium + .section--gray-medium .section__inner,
.section--gray-bright + .section--gray-bright .section__inner,
.section--ocean-blue + .section--ocean-blue .section__inner,
.section--gray--medium-light + .section--gray-medium-light .section__inner,
.section--gray-light + .section--gray-light .section__inner,
.section--gray-dark + .section--gray-dark .section__inner,
.section--blue + .section--blue .section__inner {
  padding-top: 0;
}

.section:not(.section--gray-medium):not(.section--gray-bright):not(.section--ocean-blue):not(.section--gray--medium-light):not(.section--gray-light):not(.section--gray-dark):not(.section--green):not(.section--blue)
  + .section:not(.section--gray-medium):not(.section--gray-bright):not(.section--ocean-blue):not(.section--gray--medium-light):not(.section--gray-light):not(.section--gray-dark):not(.section--green):not(.section--blue) {
  .section__inner {
    padding-top: 0;
  }
}
