@keyframes slide-up {
  from {
    transform: translateY(80px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.toasts {
  bottom: 0;
  position: fixed;
  z-index: $z-messages;
  width: 100%;
  width: 100vw;
  @include breakpoint($s) {
    width: auto;
    transform: translateX(-50%);
    left: 50%;
    .toast {
      border-width: 0px;
      margin-bottom: 12px;
    }
  }
  .toast {
    animation-name: slide-up;
    animation-duration: 0.8s;
    animation-fill-mode: both;
    background-color: $color-dark;
    border-bottom: 1px solid rgba(0, 0, 0, 0.25);
    box-shadow: 0 6px 10px 2px rgba(59, 73, 95, 0.25);
    color: #fff;
    display: grid;
    grid-template-columns: 80px auto 80px;
    &.toast--error .icon .square {
      background-color: $color-red;
      background-image: url('./img/error.svg');
    }
    &.toast--info .icon .square {
      background-color: $color-blue;
      background-image: url('./img/info.svg');
    }
    &.toast--success .icon .square {
      background-color: $color-green;
      background-image: url('./img/success.svg');
    }
    .icon,
    .message {
      animation-name: fade-in;
      animation-duration: 0.8s;
      animation-delay: 0.4s;
      animation-fill-mode: both;
      display: inline-flex;
      align-items: center;
      vertical-align: top;
    }
    .icon {
      justify-content: center;
    }
    .square {
      width: 30px;
      height: 30px;
      background-repeat: no-repeat;
      background-size: 20px;
      background-position: center;
    }
    .message {
      padding: 8px 25px 8px 0px;
      p {
        font-size: 16px;
        font-weight: 600;
      }
    }
    .dismiss {
      .toast__close-button {
        width: 30px;
        height: 30px;
        display: block;
        margin-left: auto;
      }
    }
  }
}
