.user-figure {
  img {
    max-height: 80px;
  }

  &.user-figure--inline {
    display: inline-block;
    vertical-align: top;
    padding: $size-16 $size-30;
    width: 130px;
  }
  &.user-figure--float {
    float: left;
    margin-right: $size-16;
  }
  @media screen and (max-width: $s) {
    &.user-figure--inline {
      padding: $size-8 $size-16;
    }
  }
}
