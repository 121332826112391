@import 'colors.scss';

// Sizes
$size-4: 4px;
$size-8: 8px;
$size-12: 12px;
$size-14: 14px;
$size-16: 16px;
$size-18: 18px;
$size-20: 20px;
$size-22: 22px;
$size-24: 24px;
$size-26: 26px;
$size-28: 28px;
$size-30: 30px;
$size-32: 32px;
$size-36: 36px;
$size-40: 40px;
$size-50: 50px;
$size-60: 60px;
$size-64: 64px;
$size-80: 80px;
$size-110: 110px;

// Z-indexes
$z-below: -1;
$z-neutral: 0;
$z-promoted: 1;
$z-modal: 100;
$z-messages: 101;

// Breakpoints
$xs-minor: 414px;
$xs: 468px;
$s-minor: 640px;
$s: 767px;
$m: 968px;
$l: 1240px;
$xl: 1300px;
$xxl: 1920px;
$xxxl: 2000px;
$designer: 2400px;

$site-width: 1140px;
